import { z } from 'zod';
import { convertToIsoUtc } from '@shared/utils/date-utils';
import { RegulationActionDtoSchema } from './models';

// Common schemas
const PlatformDtoSchema = z.object({
    id: z.string(),
    name: z.string(),
});

export const TrackingStatusEnum = z.enum([
    'on_time',
    'delayed',
    'early',
    'lost',
    'stationary',
    'no_signal',
    'finished',
    'unspecified_delay',
]);

const StopServiceDtoSchema = z.object({
    platform: PlatformDtoSchema,
    departureSchedule: z.string().transform(convertToIsoUtc).nullish(),
    arrivalSchedule: z.string().transform(convertToIsoUtc).nullish(),
    isCancelled: z.boolean().optional(),
});

const TripStopDtoSchema = z.object({
    theoreticalSchedule: z.string().transform(convertToIsoUtc),
    realtimeSchedule: z.string().transform(convertToIsoUtc).nullish(),
    stopArea: z.object({
        name: z.string(),
        platforms: z.array(PlatformDtoSchema).optional(),
    }),
});

const CommercialLineDtoSchema = z.object({
    name: z.string(),
    textColor: z.string().trim().optional(),
    backgroundColor: z.string().trim().optional(),
});

/**
 * Synthese retourne des espaces au lieu de string vide quand pas de valeurs ...
 * Ce qui est truthy dans le code ...
 */
const DriverDtoSchema = z.object({
    name: z.string().trim(),
    phone: z.string().trim(),
});

// Main schemas
export const TripSummaryDtoSchema = z.object({
    id: z.string(),
    number: z.string(),
    commercialLine: CommercialLineDtoSchema,
    vehicleService: z.object({
        name: z.string(),
    }),
    isCancelled: z.boolean(),
    hasRegulation: z.boolean(),
    departure: TripStopDtoSchema,
    arrival: TripStopDtoSchema,
    tracking: z.object({
        hasTracking: z.boolean(),
        nextStop: z
            .object({
                theoreticalData: StopServiceDtoSchema,
                realtimeData: StopServiceDtoSchema,
                stopArea: z.object({
                    name: z.string(),
                    platforms: z.array(PlatformDtoSchema).optional(),
                }),
            })
            .optional(),
        status: TrackingStatusEnum.optional(),
    }),
});

const StopDetailsDtoSchema = z.object({
    rank: z.number(),
    isDRTArea: z.boolean().optional(),
    cityName: z.string(),
    stopArea: z.object({
        name: z.string(),
        platforms: z.array(PlatformDtoSchema).optional(),
    }),
    regulationActions: z.array(RegulationActionDtoSchema),
    theoreticalData: StopServiceDtoSchema,
    realtimeData: StopServiceDtoSchema.optional(),
    isOnDemand: z.boolean(),
    isBoardingAuthorized: z.boolean(),
    isAlightingAuthorized: z.boolean(),
    tripNumber: z.string(),
});

const TripDtoSchema = z.object({
    id: z.string(),
    stops: z.array(StopDetailsDtoSchema),
});

const TransportModeTypeEnumSchema = z.enum(['Bus', 'Train', 'Tram', 'Bateau', 'Métro']);

export const TripDetailsDtoSchema = z.object({
    id: z.string(),
    number: z.string(),
    path: z.object({
        name: z.string(),
    }),
    operationDate: z.string().transform(convertToIsoUtc),
    driver: DriverDtoSchema,
    commercialLine: CommercialLineDtoSchema,
    vehicleService: z.object({
        name: z.string(),
        id: z.string(),
    }),
    transportModeType: TransportModeTypeEnumSchema,
    trips: z.array(TripDtoSchema),
});

export const GetAllTripsResponseDtoSchema = z.object({
    trips: z.array(TripSummaryDtoSchema),
    totalNumberOfTrips: z.number(),
});

// Type definitions
export type GetAllTripsResponseDto = z.infer<typeof GetAllTripsResponseDtoSchema>;
export type TripSummaryDto = z.infer<typeof TripSummaryDtoSchema>;
export type TripDetailsDto = z.infer<typeof TripDetailsDtoSchema>;
export type TripDto = z.infer<typeof TripDtoSchema>;
export type TripStopDto = z.infer<typeof TripStopDtoSchema>;
export type StopDetailsDto = z.infer<typeof StopDetailsDtoSchema>;
export type StopServiceDto = z.infer<typeof StopServiceDtoSchema>;
export type PlatformDto = z.infer<typeof PlatformDtoSchema>;
export type DriverDto = z.infer<typeof DriverDtoSchema>;
export type TrackingStatus = z.infer<typeof TrackingStatusEnum>;
export type TransportModeTypeEnum = z.infer<typeof TransportModeTypeEnumSchema>;
