import { IFeatureSettings } from '@models/feature-settings.model';

export const FeatureSettings: IFeatureSettings = {
    transportCompany: 'MGN',
    'features.connections': false,
    'features.reservations': false,
    'features.trip.details.header.driver': false,
    'features.trip.details.header.vehicleService': true,
    'features.trip.details.stopTable.columns': [
        'tripNumber',
        'stopArea',
        'platform',
        'regulation',
        'theoreticalArrival',
        'theoreticalDeparture',
    ],
    'features.trip.details.stopTable.displayLinkedTrips': true,
    'features.trip.list.filter.network': true,
    'features.trip.list.filter.operationUnit': false,
    'features.trip.list.filter.vehicleService': true,
    'features.trip.list.filter.viewByReservationsPoints': false,
    'features.trip.list.filter.withAvailableReservation': false,
    'features.trip.list.table.columns': ['line', 'vehicleService', 'id', 'hasRegulation', 'departure', 'arrival'],
    'features.trip.openMapPathButton': false,
};
